<template>
  <div class="max-w-screen-sm mx-auto">

    <div class="w-5/6 mx-auto italic my-5 flex flex-row items-center">
      <img class="cursor-pointer h-24 w-24 border-white borde mr-5" src="../assets/img/skellys-tamp.png">
      <span>Skelly.cards is home to various small gaming and card related projects, ranging from indie video games to decks of cards and more.</span>
    </div>

    <div class="w-full flex flex-col gap-y-5">
       <Project v-for="item in [
               { id: 'chester-cards', 
                name: 'Chester: The Card Game', 
                desc: 'A deck of cards for a simple 3-lane head to head card game.', 
                img: 'https://skelly.cards/unknown.png'
              },               
              { id: 'ss-dd', 
                name: 'Sugar Stardust: Bubblegum Boom', 
                desc: 'Robot space punks get up to some vehicular combat.<br /><br />A small game coming ???', 
                img: 'https://skelly.cards/sugstar-vehicles.png'
              },
              { id: 'deka', 
                name: 'DEKA-DICE D20 Deck', 
                desc: 'A 72 card deck that works just like a D20 set for your favorite tabletop games!<br /><br />You can also give your game a new strategy twist and play in <b>Discard Mode</b> or with the optional deck <b>Power-Roll</b>.', 
                kickstarter: 'https://www.kickstarter.com/projects/skellypacks/deka-dice',
                img: 'https://skelly.cards/deka02.png'
              },
              { id: 'powerroll', 
                name: 'DEKA-DICE Power-Roll', 
                desc: 'An optional 35 card deck that adds a new layer of risk and reward to every roll of a die!<br /><br />Designed to be used by a tabletop group playing with <b>DEKA-DICE</b> decks, only one <b>Power-Roll</b> deck is needed for an entire table.', 
                kickstarter: 'https://www.kickstarter.com/projects/skellypacks/deka-dice',
                img: 'https://skelly.cards/powerroll.png'
              },
              { id: 'gbdeck', name: 'GB Deck Playing Cards', desc: 'A standard deck of playing cards inspired by the original handheld Gaming Brick: the <b>GB Deck</b>!<br /><br />The card back design features an explosion of handheld parts and face cards are inspired by some of the top hits for the system.', kickstarter: 'https://www.kickstarter.com/projects/blueg/pocket-power-playing-card-deck-x-handheld-gaming-legend', stock: 'Available', img: 'https://skelly.cards/gbdeck.png' },             
              { id: 'unity', 
                name: 'Sugar Stardust - Remute Unity', 
                youtube: 'https://www.youtube.com/watch?v=6BhqlWsiiqw',
                desc: 'A music video and ui design for an album released on a GBA cartridge.<br /><br />Uses characters and settings from Sugar Stardust.', 
                img: 'https://skelly.cards/suggba.jpg'
              },  
              { id: 'tokens', 
                name: 'Skelly Tokens', 
                desc: 'A set of 10 MTG tokens with a bit of a skeletal makeover.<br /><br />Features Bear, Squirrel, Food, Angel, Goat, Hydra, Bird, Cat, Sliver, and a Skeleton proxy. <br />Reverse of every card is a Treasure token!', 
                kickstarter: 'https://www.kickstarter.com/projects/skellypacks/skelly-tokens-themed-tokens-for-magic-the-gathering-players', 
                img: 'https://skelly.cards/tokens.png',
                stock: 'Available' }, 
              { id: 'skelly-overlay', 
                name: 'Skelly Overlay', 
                youtube: 'https://youtu.be/INi0231vh00?t=269',
                desc: 'An informational overlay for tracking opening packs of MTG cards.', 
                img: 'https://skelly.cards/overlay.jpg'
              },  
              { id: 'chester-one', 
                name: 'Chester One', 
                desc: 'Classic indie platforming action from 2010, free on Steam.', 
                img: 'https://skelly.cards/chester-one.png',
                steam: 'https://store.steampowered.com/app/348800/Chester_One/',
                itch: 'https://blueg.itch.io/chester-classic'
              },  
            ]" 
              :key="item.id"
              :name="item.name"
              :desc="item.desc"
              :steam="item.steam"
              :itch="item.itch"
              :youtube="item.youtube"
              :kickstarterDates="item.kickstarterDates"
              :kickstarter="item.kickstarter"
              :img="item.img" />
    </div>

  </div>
</template>

<script>

import Project from '@/components/Project.vue'

export default {
  name: 'AboutView',
  components: {
    Project
  },
  methods: {
    openKickstarter(){
      window.open('https://www.kickstarter.com/projects/skellypacks/deka-dice')
    }

  }
}
</script>
