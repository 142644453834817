<template>
<div class="w-full justify-center mx-auto flex flex-col md:flex-row max-w-screen-xl">    

    <div class="block flex flex-col gap-y-5 max-w-screen-sm">

    <div class="w-5/6 mx-auto italic my-3 flex flex-row items-center">
      <img class="cursor-pointer h-24 w-24 border-white borde mr-5" src="../assets/img/skellys-tamp.png">
      <span>Skelly.cards is home to various small gaming and card related projects, ranging from indie video games to decks of cards and more.</span>
    </div>

      <div class="w-5/6 mx-auto py-4">DEKA-DICE: D20 Deck - Use it like a standard set of D20 dice or add an optional gameplay twist to your favorite tabletop games! <br />
      <div @click="openKickstarter()" class="w-3/4 mt-2 text-center mx-auto p-3 text-lg rounded-lg cursor-pointer bg-green-500 hover:bg-green-400 duration-150 text-white"
        style="text-shadow: -1px -1px 0 #444, 1px -1px 0 #444, -1px 1px 0 #444, 1px 1px 0 #444;">
        <div class="text-3xl font-bold">View on Kickstarter!</div>
      </div>
      </div>
      <div class="mx-auto italic text-sm opacity-80">Extra decks from the Kickstarter campaign will be available for purchase soon.</div>
        
    <div class="aspect-video rounded-lg overflow-hidden">
        <iframe
        class="w-full h-full"
          height="auto"
          :src="`https://www.youtube.com/embed/g3K3s1GmUgs`"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <a href="https://www.kickstarter.com/projects/skellypacks/deka-dice" target="_blank"><img class="cursor-pointer w-full md:w-full border-white border" src="../assets/img/deka-flips.gif"></a>
      <a href="https://www.kickstarter.com/projects/skellypacks/deka-dice" target="_blank"><img class="cursor-pointer w-full md:w-full border-white border" src="../assets/img/deka-demo.jpg"></a>
      <a href="https://www.kickstarter.com/projects/skellypacks/deka-dice" target="_blank"><img class="cursor-pointer w-full md:w-full border-white border" src="../assets/img/deka-contents.gif"></a>
    </div>
</div>
</template>

<script>

export default {
  name: 'HomeView',
  components: {
  },
  methods: {
    openKickstarter(){
      window.open('https://www.kickstarter.com/projects/skellypacks/deka-dice')
    }

  }
}
</script>
